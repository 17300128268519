import FrenglishSDK from 'frenglish'
import '@babel/polyfill'

class Frenglish {
  constructor() {
    Frenglish.sdkInstance = null
    Frenglish.projectAccess = {}
    Frenglish.nodeLists = {}
    Frenglish.nodeTypes = {
      v1: [],
      v2: ["ABBR", "ACRONYM", "B", "BDO", "BIG", "CITE", "EM", "I", "KBD", "Q", "SMALL", "STRONG", "SUB", "SUP", "U"],
      v3: ["A", "BDI", "BR", "DEL", "DFN", "INS", "S", "SPAN"]
    }
    Frenglish.translations = {}
    Frenglish.DATAKEY = "data-FRENGLISH-key"
  }

  // ------- SETUP FUNCTIONS --------
  static isPublicApiKey(key) {
    // Public API keys are 32 character hexadecimal strings
    const publicKeyPattern = /^[a-f0-9]{32}$/i;
    return publicKeyPattern.test(key);
  }

  static async initialize(config) {
    // Validate that this is a public API key
    if (!this.isPublicApiKey(config.api_key)) {
      throw new Error(
        'Invalid API key format. Please use your public API key. ' +
        'Private API keys should only be used server-side and not exposed in client applications.'
      );
    }

    this.projectAccess = {
      api_key: config.api_key
    }
    if (window.FrenglishInstance && window.FrenglishInstance.sdkInstance) {
      console.warn('Frenglish is already initialized')
      return window.FrenglishInstance
    }

    await this.initializeSDK(config.api_key)
    await this.setup()

    // Handle initial URL and language
    await this.handleUrlChange()

    // Initial content scan and translation for all supported languages
    await this.initialTranslationSetup()

    // Now that we have all translations, add the language selector
    await this.addLanguageSelector()

    // Add MutationObserver to detect DOM changes
    await this.setupMutationObserver()

    window.addEventListener('popstate', () => this.handleUrlChange())
    window.FrenglishInstance = this
    // Emit initialization event
    window.dispatchEvent(new Event('frenglishLoaded'))

    // Add link click handler
    document.addEventListener('click', async (e) => {
      const link = e.target.closest('a')
      if (!link) return

      // Only handle internal links
      if (link.hostname === window.location.hostname) {
        e.preventDefault()

        const currentLang = await this.detectLanguage()
        const url = new URL(link.href)
        const pathSegments = url.pathname.split('/').filter(Boolean)

        // If the first segment isn't a language code, add it
        if (pathSegments[0] !== currentLang) {
          const newPath = `/${currentLang}${url.pathname}`
          window.location.href = newPath
        } else {
          window.location.href = link.href
        }
      }
    })
    return this
  }

  static async initializeSDK(apiKey) {
    try {
      this.sdkInstance = new FrenglishSDK(apiKey)
    } catch (error) {
      console.error("Failed to initialize SDK:", error)
      throw error
    }
  }

  static async setup() {
    if (this.projectAccess) return this.nodeLists
    const lists = this.nodeTypes
    lists.v2.unshift("#text")
    this.nodeLists.mergeNodesList = lists
    return this.nodeLists
  }

  // ------- DETECT TEXT CHANGES FUNCTIONS --------

  static async initialTranslationSetup() {
    try {
      await this.scanAndTranslateContent()
    } catch (error) {
      console.error('Error in initial translation setup:', error)
    }
  }

  static async getConfiguration() {
    const CACHE_DURATION = 30 * 60 * 1000 // 30 minutes in milliseconds
    const cachedData = localStorage.getItem('frenglish_configuration')

    if (cachedData) {
      const parsed = JSON.parse(cachedData)
      const now = new Date().getTime()

      // Check if cache is still valid (less than 30 minutes old)
      if (parsed.lastTimeCached && (now - parsed.lastTimeCached) < CACHE_DURATION) {
        return parsed.data
      }
    }

    // If we get here, either there's no cache or it's expired
    const newLanguageConfig = await this.sdkInstance.getProjectSupportedLanguages()
    const cacheObject = {
      data: newLanguageConfig,
      lastTimeCached: new Date().getTime()
    }

    localStorage.setItem('frenglish_configuration', JSON.stringify(cacheObject))
    return newLanguageConfig
  }

  static async setupMutationObserver() {
    // Create an observer instance
    const observer = new MutationObserver(async () => {
      this.scanAndTranslateContent()
    })

    // Start observing
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      characterData: true
    })
  }

  static async handleUrlChange() {
    if (window.frenglishSettings?.platform === 'wordpress') {
      // Detect language change and update it
      const lang = await this.detectLanguage()

      await this.setLanguage(lang)

      // Trigger new content scan and translation
      this.scanAndTranslateContent()
    }
  }

  // ------- SCRAPPING FUNCTIONS --------

  static async scanAndTranslateContent() {
    // Scrape new text from the current page
    const newStrings = await this.scrapeTextToTranslate()

    if (Object.keys(newStrings).length > 0) {
      // Create a unique filename based on the current path
      const sanitizedPath = window.location.pathname.replace(/[^a-zA-Z0-9-_]/g, '_')
      const filename = `${sanitizedPath}.json`

      //udpate text map with new endpoint
      //get text map and pass it into requestTransltion
      await this.requestTranslation(
        [JSON.stringify(newStrings)],
        false,
        [filename]
      )
    } else {
      const textMap = await this.sdkInstance.getTextMap()

      // Apply translations 
      this.applyTranslations(textMap)
    }
  }

  // 1. Scrape text
  // 2. Return data-key : text for untranslated text
  // 3. Elsewhere we check what should and shouldn't be translated
  static async scrapeTextToTranslate() {
    const extractedStrings = {}
    const processedNodes = new Set()

    // Get ALL text nodes in one pass
    const getAllTextNodes = (root) => {
      const walker = document.createTreeWalker(
        root,
        NodeFilter.SHOW_TEXT,
        {
          acceptNode: (node) => {
            if (processedNodes.has(node)) return NodeFilter.FILTER_REJECT
            if (!this.shouldTranslateNode(node)) return NodeFilter.FILTER_REJECT
            return NodeFilter.FILTER_ACCEPT
          }
        }
      )

      const textNodes = []
      let node = walker.nextNode()
      while (node) {
        textNodes.push(node)
        node = walker.nextNode()
      }
      return textNodes
    }

    // Get all text nodes at once
    const allTextNodes = getAllTextNodes(document)

    // Process all nodes in batch
    allTextNodes.forEach(async node => {
      const text = node.textContent.trim()
      if (text) {
        const parentElement = node.parentElement

        // Check if parent already has a specific data-key
        const existingDataKey = parentElement?.getAttribute(this.DATAKEY)

        if (existingDataKey) {
          processedNodes.add(node)

        } else if (!parentElement?.hasAttribute(this.DATAKEY)) {
          // Use text as both key and value if no specific data-key exists
          extractedStrings[text] = text
          //}
          const span = document.createElement('span')
          span.setAttribute(this.DATAKEY, text)
          span.textContent = text
          node.parentNode.replaceChild(span, node)
          processedNodes.add(span.firstChild)
        }
      }
    })

    return extractedStrings
  }

  // Check if a node should be translated or not. Not all text should be translated
  static shouldTranslateNode(node) {
    const parent = node.parentElement
    if (!parent) return false

    const skipTags = ['SCRIPT', 'STYLE', 'CODE', 'PRE', 'NOSCRIPT', 'IFRAME', 'INPUT', 'TEXTAREA']
    if (skipTags.includes(parent.tagName)) return false

    const skipClasses = ['notranslate', 'code']
    if (skipClasses.some(cls => parent.classList.contains(cls))) return false
    if (parent.hasAttribute('data-notranslate')) return false

    const text = node.textContent?.trim()
    if (!text) return false

    if (!/[a-zA-Z]/.test(text)) return false

    return true
  }

  // ------- LANGUAGE FUNCTIONS --------
  static setLanguagePreference(lang) {
    // Store in localStorage
    localStorage.setItem('frenglishLanguage', lang)
    // Also set as a cookie for server-side access
    document.cookie = `frenglishLanguage=${lang}; path=/; max-age=31536000` // 1 year expiry
  }

  static getLanguagePreference() {
    // First try localStorage
    const storedLang = localStorage.getItem('frenglishLanguage')
    if (storedLang) return storedLang

    // Then try cookie
    const cookieLang = document.cookie
      .split('; ')
      .find(row => row.startsWith('frenglishLanguage='))
      ?.split('=')[1]
    if (cookieLang) return cookieLang

    return null
  }

  static getLanguageLabelsMap() {
    const fullLanguageMap = {
      "en": "English",
      "es": "Español",
      "fr": "Français",
      "de": "Deutsch",
      "zh-cn": "简体中文",
      "zh-tw": "繁體中文",
      "ja": "日本語",
      "ko": "한국어",
      "it": "Italiano",
      "nl": "Nederlands",
      "ru": "Русский",
      "pt-pt": "Português (Portugal)",
      "pt-mz": "Português (Moçambique)",
      "pt-br": "Português (Brasil)",
      "ar": "العربية",
      "tr": "Türkçe",
      "sv": "Svenska",
      "no": "Norsk",
      "da": "Dansk",
      "fi": "Suomi",
      "pl": "Polski",
      "cs": "Čeština",
      "hu": "Magyar",
      "ro": "Română",
      "el": "Ελληνικά",
      "he": "עברית",
      "vi": "Tiếng Việt",
      "th": "ไทย",
      "id": "Bahasa Indonesia",
      "ms": "Bahasa Melayu"
    }

    return fullLanguageMap
  }

  // Modify your detectLanguage method
  static async detectLanguage() {
    const pathname = window.location.pathname
    const pathSegments = pathname.split('/').filter(Boolean)
    const firstPathSegment = pathSegments[0]
    const config = await this.getConfiguration()
    const supportedLanguages = [...new Set([config.originLanguage, ...config.languages])]

    // First check URL
    if (supportedLanguages.includes(firstPathSegment)) {
      this.setLanguagePreference(firstPathSegment)
      return firstPathSegment
    }

    // Then check stored preference
    const storedLang = this.getLanguagePreference()
    if (storedLang && supportedLanguages.includes(storedLang)) {
      // Redirect to stored language URL
      const newPath = '/' + storedLang + pathname
      window.history.replaceState({}, '', newPath)
      return storedLang
    }

    // Default to origin language
    return config.originLanguage
  }

  static async setLanguage(langCode) {
    const currentLang = await this.detectLanguage()
    if (currentLang === langCode) return

    this.setLanguagePreference(langCode)

    // Get current URL parts
    const url = new URL(window.location.href)
    const pathSegments = url.pathname.split('/').filter(Boolean)
    const config = await this.getConfiguration()
    const supportedLanguages = [...new Set([config.originLanguage, ...config.languages])]

    // Update path-based language
    if (supportedLanguages.includes(pathSegments[0])) {
      // Replace existing language in path
      pathSegments[0] = langCode
    } else {
      // Add language to path
      pathSegments.unshift(langCode)
    }
    const newPath = '/' + pathSegments.join('/')

    if (newPath !== url.pathname) {
      window.history.pushState({}, '', newPath)
    }
  }

  static async addLanguageSelector() {
    const select = document.createElement('select')
    const config = await this.getConfiguration()
    const languageLabels = this.getLanguageLabelsMap()
    const supportedLanguages = [...new Set([config.originLanguage, ...config.languages])]
      .map(langCode => ({
        code: langCode,
        label: languageLabels[langCode] || langCode // Fallback to code if no label exists
      }))
    select.style.cssText = `
          position: fixed;
          bottom: 20px;
          right: 20px;
          z-index: 2147483647;
          padding: 8px 30px 8px 12px;
          border: 1px solid #ddd;
          border-radius: 4px;
          background-color: white;
          color: #333;
          font-weight: 500;
          font-size: 14px;
          cursor: pointer;
          outline: none;
          transition: all 0.3s;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          appearance: none;
          -webkit-appearance: none;
          background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
          background-repeat: no-repeat;
          background-position: right 8px center;
          background-size: 10px;
      `

    select.addEventListener('mouseover', () => {
      select.style.borderColor = '#007bff'
      select.style.boxShadow = '0 2px 15px rgba(0, 0, 0, 0.15)'
    })

    select.addEventListener('mouseout', () => {
      select.style.borderColor = '#ddd'
      select.style.boxShadow = '0 2px 10px rgba(0, 0, 0, 0.1)'
    })

    supportedLanguages.forEach(lang => {
      const option = document.createElement('option')
      option.value = lang.code
      option.textContent = lang.label
      select.appendChild(option)
    })

    // Set the selected option after we have the current language
    const currentLang = await this.detectLanguage()
    const selectedOption = select.querySelector(`option[value="${currentLang}"]`)
    if (selectedOption) {
      selectedOption.selected = true
    }

    select.addEventListener('change', async (event) => {
      const newLang = event.target.value

      // Get current path segments excluding the language code
      const currentPath = window.location.pathname
        .split('/')
        .filter(Boolean)
        .filter(segment => segment !== this.getLanguagePreference())
        .join('/')

      // Construct new URL with language prefix
      const newPath = `/${newLang}/${currentPath}`

      history.pushState(null, '', newPath)
      // Detect language change and update it
      const lang = await this.detectLanguage()
      await this.setLanguage(lang)
      // Trigger new content scan and translation
      this.scanAndTranslateContent()
    })

    document.body.appendChild(select)
  }

  // ------- TRANSLATION FUNCTIONS --------

  static async requestTranslation(extractedStrings, isFullTranslation = false, filenames) {
    try {
      // Send for translation
      await this.sdkInstance.translate(extractedStrings, isFullTranslation, filenames)
      const textMap = await this.sdkInstance.getTextMap()

      // Apply translations 
      this.applyTranslations(textMap)
    } catch (error) {
      console.error("Error in requestTranslation:", error)
    }
  }

  static async applyTranslations(textMapFile) {
    try {
      const config = await this.getConfiguration()
      // First, organize by hash to get original text (English) as keys
      const translationsByHash = {}
      textMapFile.forEach(entry => {
        const [hash, lang] = entry.path
        if (!translationsByHash[hash]) {
          translationsByHash[hash] = { hash }
        }
        if (lang === config.originLanguage) {
          translationsByHash[hash].originalText = entry.value
        } else {
          if (!translationsByHash[hash].translations) {
            translationsByHash[hash].translations = {}
          }
          translationsByHash[hash].translations[lang] = entry.value
        }
      })

      // Now organize by language using original text as key
      const translationsByLang = {}
      Object.values(translationsByHash).forEach(({ originalText, translations = {} }) => {
        if (!originalText) return // Skip if no English version found

        // Add original language translations
        if (!translationsByLang[config.originLanguage]) {
          translationsByLang[config.originLanguage] = {}
        }
        translationsByLang[config.originLanguage][originalText] = originalText

        // Add other languages translations
        Object.entries(translations).forEach(([lang, translatedText]) => {
          if (!translationsByLang[lang]) {
            translationsByLang[lang] = {}
          }
          translationsByLang[lang][originalText] = translatedText
        })
      })

      // Apply translations to the DOM
      const currentLanguage = await this.detectLanguage()
      const translations = translationsByLang[currentLanguage]

      if (!translations) {
        console.warn(`No translations available for language: ${currentLanguage}`)
        return
      }

      const allElements = document.querySelectorAll(`[${this.DATAKEY}]`)
      allElements.forEach(element => {
        const key = element.getAttribute(this.DATAKEY)
        const translatedText = translations[key]

        if (translatedText && element.textContent !== translatedText) {
          element.textContent = translatedText
        }
      })
    } catch (error) {
      console.error('Error applying translations:', error)
    }
  }
}

Frenglish.sdkInstance = null
Frenglish.projectAccess = {}
Frenglish.nodeLists = {}
Frenglish.nodeTypes = {
  v1: [],
  v2: ["ABBR", "ACRONYM", "B", "BDO", "BIG", "CITE", "EM", "I", "KBD", "Q", "SMALL", "STRONG", "SUB", "SUP", "U"],
  v3: ["A", "BDI", "BR", "DEL", "DFN", "INS", "S", "SPAN"]
}
Frenglish.translations = {}
Frenglish.DATAKEY = "data-FRENGLISH-key"

document.addEventListener('DOMContentLoaded', () => {
  if (frenglishSettings && frenglishSettings.api_key) {
    Frenglish.initialize({
      api_key: frenglishSettings.api_key,
    })
  } else {
    console.warn("Frenglish API key is not set.")
  }
})

if (typeof window !== 'undefined') {
  window.Frenglish = Frenglish
}